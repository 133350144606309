/* Main Product Display Container */
.product-display {
  display: flex;
  justify-content: center;
  align-items: flex-start;  /* Align items to the start to avoid centering the entire layout */
  padding: 20px;
  min-height: 100vh;
  margin-top: 5%;
}

.product-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 95%;
}

/* Product Image Section */
.product-image {
  position: relative;
  width: 40%;            /* Set product image width to 25% */
  height: 400px;         /* You can adjust this as needed, or use a min-height */
  margin-right: 20px;    /* Add margin between image and details */
  overflow: hidden;      /* Prevent overflow */
  margin-top: 5%;
}

.carousel {
  position: relative;    /* Make this the reference point for the buttons */
  width: 100%;
  height: 100%;           /* Ensure the carousel takes up full height of its container */
}

.carousel img {
  width: 100%;
  height: 100%;           /* Image height is set to fill the container */
  object-fit: contain;    /* Ensures the whole image is visible, maintaining aspect ratio */
  transition: transform 0.5s ease-in-out;
}

/* Position the navigation buttons absolutely */
.carousel button {
  position: absolute;
  top: 50%;                /* Vertically center the buttons */
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;             /* Ensure buttons are above images */
}

.carousel button:hover {
  background-color: rgba(0, 0, 0, 0.7);  /* Darker background on hover */
}

/* Left button on the left side */
.carousel button:first-of-type {
  left: 10px;
}

/* Right button on the right side */
.carousel button:last-of-type {
  right: 10px;
}

/* Product Details Section */
.product-details {
  flex: 2;                /* Take up 75% of the space */
  padding: 30px;
  color: #333;
  display: flex;
  flex-direction: column;  /* Stack the content vertically */
}

/* Heading Styles */
.product-details h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.product-details .product-subtitle {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

/* Price */
.price {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px 0;
}

.price span {
  font-size: 1rem;
  color: #444;
}

/* Features Section */
.features {
  margin-top: 20px;
}

.features h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #444;
}

.features ul {
  list-style: none;
  padding: 0;
}

.features ul li {
  margin: 10px 0;
  color: #333;
  font-size: 1rem;
}

.features ul li:before {
  content: "•";
  color: #f9c500; /* Yellow dot color */
  font-size: 1.5rem;
  margin-right: 10px;
}

/* Buttons Section */
.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.action-buttons .btn {
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.action-buttons .btn:hover {
  opacity: 0.9;
}

.action-buttons .dealer-btn {
  background-color: #f9c500;
  color: #000;
}

.action-buttons .buy-btn {
  background-color: #000;
  color: #fff;
}

.product-quantity-price {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.product-dropdown label {
  font-weight: bold;
  margin-right: 10px;
}

.product-dropdown select {
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.product-price {
  font-size: 1.25rem;
  font-weight: bold;
  color: #007bff;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .product-container {
    flex-direction: column;  /* Stack image and details vertically */
  }

  .product-image {
    width: 100%;      /* Image takes full width on small screens */
    height: 300px;    /* Adjust height for smaller screens */
    margin-bottom: 20px;
  }

  .product-details {
    width: 100%;      /* Details take full width on small screens */
    padding: 20px;
  }

  .product-display {
    margin-top: 12%;
  }

  .features ul li {
    font-size: 0.9rem;
  }

  .action-buttons .btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}
