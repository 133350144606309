@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url('../src/images/connect.avif') fixed center center;
}

.m-inherit {
  margin: inherit;
}
@media (min-width:320px)  { .margin-top-2 {margin-top: 2rem;} }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }


/* Blog post */
.blog-post-container {
  font-family: "Arial", sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  /* margin-top: 2rem; */
}

.blog-post-title {
  font-size: 2.5rem;
  color: #003366;
  margin-bottom: 20px;
  text-align: center;
}

.blog-post-subtitle {
  font-size: 1.75rem;
  color: #003366;
  margin-top: 40px;
  margin-bottom: 20px;
}

.blog-post-paragraph {
  font-size: 1.125rem;
  margin-bottom: 20px;
  text-align: justify;
}

.blog-post-image {
  width: -webkit-fill-available;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-post-image-choose-right {
  /* width: 100%; */
  height: 12rem;
  border-radius: 5px;
  margin-bottom: 20px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
}

.blog-post-conclusion {
  font-size: 1.5rem;
  color: #003366;
  margin-top: 40px;
  margin-bottom: 20px;
}

.blog-post-button {
  display: inline-block;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
  color: #fff;
  padding: 10px 20px;
  font-size: 1.125rem;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
}

.blog-post-button:hover {
  background-color: #0056b3;
}

.blog-card-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.blog-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.blog-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-card-content {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.blog-card-title {
  font-size: 1.5rem;
  color: #003366;
  margin-bottom: 10px;
}

.blog-card-excerpt {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 20px;
  flex-grow: 1;
}

.blog-card-button {
  align-self: flex-start;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
  color: #fff;
  padding: 10px 20px;
  font-size: 1.125rem;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
}

.blog-card-button:hover {
  background-color: #0056b3;
}